<template>
    <div class="showPdf-out">
        <!-- <el-input
            v-model="textarea1"
            
            type="textarea"
            placeholder="Please input"
            :autosize="{ minRows: 1, maxRows: 5 }"
        />
        <el-button @click="inputs">111</el-button> -->
        <iframe class="showPdf" src="/pdfs.pdf"></iframe>
    </div>
</template>

<script>
export default {
    name:"Bill",
    components:{},
    data(){
        return{
            textarea1:''
        }
    },
    methods:{
        inputs(){
            let arrLest = []
            arrLest = this.textarea1.split(/[(\r\n)\r\n]+/)
            let trys = []
            // console.log(trys.trim())
            arrLest.forEach((item,i)=>{
                // item.trim()
                trys.push(item.trim())
            })
            console.log(trys)
        },
        unique(arr) {
            const res = new Map();
            return arr.filter((a) => !res.has(a) && res.set(a, 1))
        }
    },
}
</script>

<style lang="scss" scoped>
.showPdf-out{
    height: 100%;
    overflow-y: overlay;
    padding-right: 18px;
}
.showPdf{
    width: 100%; 
    height: 99.3%;
}
</style>
